import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Stepper",
        "componentName": "Stepper"
      }}>{`<Stepper steps={['A One', 'A Two', 'A One-Two', 'Three', 'Four']} />
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Stepper" mdxType="Props" />
    <h2 {...{
      "id": "steps",
      "style": {
        "position": "relative"
      }
    }}>{`Steps`}</h2>
    <p><inlineCode parentName="p">{`default: []`}</inlineCode></p>
    <p>{`The strings passed in this array will be the title of the Stepper's steps.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Stepper steps={['A One', 'A Two', 'A One-Two', 'Three', 'Four']} />
`}</code></pre>
    <h2 {...{
      "id": "active-step-index",
      "style": {
        "position": "relative"
      }
    }}>{`Active Step Index`}</h2>
    <p><inlineCode parentName="p">{`default: 0`}</inlineCode></p>
    <p>{`To visually advance to a next step, you can increase the passed in
`}<inlineCode parentName="p">{`activeStepIndex`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [currentStep, setCurrentStep] = React.useState(0);

  return (
    <React.Fragment>
      <Stepper
        steps={['A One', 'A Two', 'A One-Two', 'Three', 'Four']}
        activeStepIndex={currentStep}
        mb={3}
      />
      <Button
        mr={3}
        onClick={() => setCurrentStep(Math.max(0, currentStep - 1))}
      >
        Previous
      </Button>
      <Button onClick={() => setCurrentStep(Math.min(4, currentStep + 1))}>
        Next
      </Button>
    </React.Fragment>
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      